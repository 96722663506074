import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import bread from '../assets/images/404_Illustration.png';
import { ScContainer } from '../components/container/styled';
import { CustomButton } from '../components/custom-button';
import { ScTop } from '../components/Top/styled';
import { MainLayout } from '../layouts/main-layout';
import {
	ScErrorLayout,
	ScErrorLayoutImageWrapper,
	ScErrorLayoutTextWrapper,
	ScLargeTitle,
} from '../sections/Error/styled';
import {SEO} from "../components/Seo/Seo";

const ErrorPage = () => {
	const theme = useTheme();
	return (
		<MainLayout>
			<ScErrorLayout>
				<ScTop disableHeight>
					<ScContainer>
						<ScErrorLayoutImageWrapper>
							<ScLargeTitle centered color={theme?.colors.wheat}>
								oooooops!
							</ScLargeTitle>
							<img src={bread} alt="bread" />
						</ScErrorLayoutImageWrapper>
						<ScErrorLayoutTextWrapper>
							<h5>404 - PAGE NOT FOUND</h5>
							<p>
								The page you are looking for might have been removed, had its name changed or is
								temporarily unavailable.
							</p>
							<Link to={'/'}>
								<CustomButton
									color={theme?.colors.darkText}
									background={theme?.colors.wheat}
									border={theme?.colors.darkText}
								>
									Go to homepage
								</CustomButton>
							</Link>
						</ScErrorLayoutTextWrapper>
					</ScContainer>
				</ScTop>
			</ScErrorLayout>
		</MainLayout>
	);
};

export default ErrorPage;

export const Head = () => (
	<SEO title="Page Not Found | Gogeta Salary Sacrifice Scheme"
			 description="Oops! The page you're looking for can't be found. Visit Gogeta to explore salary sacrifice benefits for employees, employers, and partners."/>
)
